import React from "react"
import Helmet from "react-helmet"

export default () => (
  <Helmet>
    <title>ササタニキカク　Webエンジニアリング・コンサルティング</title>
    <meta
      name="title"
      content="ササタニキカク　Webエンジニアリング・コンサルティング"
    />
    <meta
      name="description"
      content="ササタニキカクです。ウェブ技術は日々進化していますが、同時によりたくさんのことができるようになってきています。ウェブでものを売りたい、現在進行中の開発プロジェクトで人手がたりないなど、何かお手伝いができることがあれば相談だけでも是非ご連絡ください！Webを身近にもっと楽しく　神奈川県横浜市青葉区　"
    />

    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.sasatanikikaku.com/" />
    <meta
      property="og:title"
      content="ササタニキカク　Webエンジニアリング・コンサルティング"
    />
    <meta
      property="og:description"
      content="ササタニキカクです。ウェブ技術は日々進化していますが、同時によりたくさんのことができるようになってきています。ウェブでものを売りたい、現在進行中の開発プロジェクトで人手がたりないなど、何かお手伝いができることがあれば相談だけでも是非ご連絡ください！Webを身近にもっと楽しく　神奈川県横浜市青葉区　"
    />
    <meta
      property="og:image"
      content="https://www.sasatanikikaku.com/img/logo.png"
    />

    <meta property="twitter:card" content="summary" />
    <meta property="twitter:url" content="https://www.sasatanikikaku.com/" />
    <meta
      property="twitter:title"
      content="ササタニキカク　Webエンジニアリング・コンサルティング"
    />
    <meta
      property="twitter:description"
      content="ササタニキカクです。ウェブ技術は日々進化していますが、同時によりたくさんのことができるようになってきています。ウェブでものを売りたい、現在進行中の開発プロジェクトで人手がたりないなど、何かお手伝いができることがあれば相談だけでも是非ご連絡ください！Webを身近にもっと楽しく　神奈川県横浜市青葉区　"
    />
    <meta
      property="twitter:image"
      content="https://www.sasatanikikaku.com/img/logo.png"
    />
  </Helmet>
)
